<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Withdraws">       <h3>Банки - Noros payments</h3>     </div>

    <div>       <BanksEditDialog mode="new" v-on:ok="save"></BanksEditDialog>    </div>

    <div>
      <v-data-table
          :items="banks"
          :headers="headers"
          class="elevation-1"
          sort-by="id"
          sort-desc

          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.country="{ item }"> {{ item.country }}</template>
        <template v-slot:item.name="{ item }"> {{ item.name }}</template>
        <template v-slot:item.rate="{ item }"> {{ item.rate }}</template>

        <template v-slot:item.logo="{ item }"> <v-img height="32" width="128" :src="item.logo"/> </template>

        <template v-slot:item.sort="{ item }"> {{ item.sort }}</template>

        <template v-slot:item.status="{ item }">  {{ item.status ? "Активна" : "Выключена" }}
          <v-btn dense class="indigo" text @click="setActive(item, !item.status)">{{ item.status ? "Откл" : "Вкл" }}
          </v-btn>
        </template>

        <template v-slot:item.actions="{ item }">
          <BanksEditDialog caption="правка" mode="edit" v-bind:item="item" v-on:ok="save"></BanksEditDialog>
        </template>

      </v-data-table>
    </div>
  </div>

</template>

<script>


/*

query MyQuery {
  banks {
    status
    rate
    name
    logo
    id

  }
}
 */


import BanksEditDialog from '../components/dialogs/BanksEditDialog.vue'
import gql from "graphql-tag";
import {getHeaders} from "../store";

import {fetcher} from "../store";
import Snackbar from "../components/controls/Snackbar.vue";

const RestApiUrl = process.env.VUE_APP_REST_API_URL_ADMIN + "/bank"

export default {
  name: 'Banks',

  components: {
    BanksEditDialog, Snackbar
  },

  apollo: {
    $subscribe: {
      banks: {
        query: gql`subscription  {
                        banks {
                            status
                            rate
                            name
                            logo
                            id
                            sort
                            country
                        }
                    }`,
        result({data}) {
          this.banks = data.banks;
        },
      },
    },
  },


  data: () => ({

    banks: [],

    emptyItem: {
      status: "",
      rate: "",
      name: "",
      logo: "",
      sort:0
    },

    headers: [
      {text: 'id', value: 'id'},

      {text: 'Название', value: 'name'},

      {text: 'Страна', value: 'country'},

      {text: 'Курс', value: 'rate'},
      {text: 'Статус', value: 'status'},
      {text: 'Лого', value: 'logo'},
      {text: 'Порядок', value: 'sort'},
      {text: 'Действия', value: 'actions'},
    ],
  }),


  methods: {


    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    async setActive(item, active) {
      item.status = active

      const formData = new FormData();
      formData.append("status", active)

      let headers = await getHeaders()
      //headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formData
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    compare2(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);
      if (keys1.length !== keys2.length) {
        return false;
      }

      let kne = []

      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          kne.push(key)
        }
      }

      return kne;
    },

    async save(item, mode, orig) {

      const formData = new FormData();

      if (mode === 'edit') {
        let kne = this.compare2(item, orig)

        if (kne.length === 0){
          alert("не изменено")
          return
        }

        for (let k in kne) {
          formData.append(kne[k], item[kne[k]])
        }

      } else
// mode new
        for (let i in item) {
          console.log(item)
          formData.append(i, item[i])
        }

      let headers = await getHeaders()

      const requestOptions = {
        method: mode === "new" ? 'POST' : 'PATCH',
        headers: headers,
        body: formData
      };

      let url = `${RestApiUrl}/${mode === "new" ? "" : item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    } ,
  },

}
</script>
