<template>
  <div>
    <Select
        :selectLabel="selectLabel"
        :getItemText="getItemText"
        :getItemData="getItemData"
        :items="items"
        :val.sync="val2"
    />
  </div>
</template>

<script>

//  !!!  this is basic  select control - simple reusable version with inline gql query !!!
// first make copy  of this file with new name
// then you must change some only next steps
// use new component as this <SelectNetwork v-bind:val.sync="item.network" ></SelectNetwork>

// !!! some problem: if you change model from  external , this list is not updates!!!

import Select from "./Select.vue";
import gql from "graphql-tag";

export default {
  //------------------------------- 0 define name and selectLabel ---------------------
  name: "SelectNetwork",

  data: function () {
    return {
      val2: null,
      items: null,
      selectLabel: "Network"
    }
  },

  props: ['val'],

  //------------------------------- 1 define query ---------------------
  apollo: {

    networks: {
      query: gql`
query MyQuery {
  networks {
    id
    name
  }
}
`,
      //------------------------------- 2 set dataset name from query ---------------------
      result({data}) {
        this.items = data.networks;//here "networks"
      },
    },

  },

  components: {Select},

  async mounted() {
    this.val2 = this.val
  },

  watch: {

    val2() {
      this.$emit('update:val', this.val2)
    }
  },

  methods: {

    getItemText(item) {
      //------------------------------- 3  list item text ---------------------
      return item.id + ": " + item.name;
    },

    getItemData(item) {
      //------------------------------- 4 return while select ---------------------
      return item.id;
    },

  },
}
</script>

<style scoped>

</style>