<template>


  <v-container fluid class="d-flex flex-wrap pa-0 ma-0 ">
<span class="pa-2 ">Балансы</span>
      <div class="pa-2 " v-for="b  in balances " :key="b.ticker">{{ b.ticker }}
        <FinanceCell :value="b.amount"/>
      </div>

  </v-container>


</template>

<script>

import gql from "graphql-tag";

//import WalletEditDialog3 from "./dialogs/WalletEditDialog3.vue";
import {getHeaders} from "../store";
//import UserWallets from "./UserWallets";


const RestApiUrl = process.env.VUE_APP_REST_API_URL_MERCHANT + "/withdrawal"

import {fetcher} from "../store";
import FinanceCell from "./controls/FinanceCell.vue";


export default {
  name: 'BalancesUser',
  components: {
    FinanceCell
  },
  apollo: {
    $subscribe: {
      withdrawals: {
        query: gql`

subscription MyQuery($user_id: Int ) {
  balances(where: {user_id: {_eq: $user_id}  } , order_by: {ticker: asc}) {
    amount
    ticker
    user {
      username
    }
  }
}

`,

        variables() {
          return {
            user_id: this.user_id,

          }
        },

        result({data}) {
          this.balances = data.balances;
        },
      },
    },
  },

  data: () => ({

    from: "2023-01-01",
    to: "2023-12-31",

    filterByDatePeriod: false,
    balances: [],


    emptyItem: {
      address: "",
      net: "",
    },

    headers: [
      {text: 'Тикер', value: 'ticker'},
      {text: 'Обьем', value: 'amount'},
    ],
  }),

  computed: {
    payoutAddresses() {
      return this.$store.state.user.payoutAddresses
    },

    user_id() {
      return this.$store.state.user.id
    },

  },

  methods: {


    getDownloadLink(image) {
      //console.log(image);
      if (image === null)
        return;

      return `${process.env.VUE_APP_FILES_BASE}/proof/withdrawal/${image.name}?type=${image.mimetype}`

    },

    onPerodChanged(val) {
      this.from = val.from
      this.to = val.to
    },

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    async del(item) {


      /*

отменить выплату
app.delete("/api/merchant/payout/:id");
      */

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'DELETE',
        headers: headers,
        //body:
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async withdrawnew() {


      /*
создать выплату
app.post("/api/merchant/payout");
	req.body.amount,  (float)
	req.body.addressId (int)

отменить выплату
app.delete("/api/merchant/payout/:id");
      */

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
            {
              //addressId: wallet.id,
              //amount: sum,

            })
      };

      let url = `${RestApiUrl}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async create(item) {
      //console.log(item);

      if (!item.address) {

        alert("не указан адрес")
        return;
      }

      /*
      добавить адрес выплаты
      app.post("/api/merchant/payout/address");
      req.body.address (string)
      req.body.currency (string) ( пока отправляем "USDT", позже будут еще варианты)
      req.body.network (string) (db.USDT_NETWORKS = ["TRX (TRC-20)", "Ethereum (ERC-20)"];)
      */

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
            {
              address: item.address,
              currency: 'USDT',
              network: item.network
            })
      };

      let url = `${RestApiUrl}/address`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    async finish(id, file) {
      //console.log(id, file);

      /*
        api админу
  изменить выплату
  app.patch("/api/admin/payout/:id");  (multipart/form-data)
  req.body.status (string)    ( db.WITHDRAWAL_STATUSES = ["Pending", "Processing", "Success", "Canceled"]; )
  req.files.doc (multipart/form-data)
       */

      const formData = new FormData();
      formData.append('status', 'Success')
      formData.append('doc', file)

      let headers = await getHeaders()
      //headers['Content-Type'] = 'multipart/form-data; boundary="----fucked boundary"'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formData
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)


    },


    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },

    usdt(item) {
      //  console.log(item.offer)
      return (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0
    },
  },

}
</script>
