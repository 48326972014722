<template>

  <div class="ma-2">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Transactions ma-2">
      <h3>*Транзакции мерчанта</h3>
    </div>

    <DatePeriodPicker caption="Период выборки" :from="from" :to="to" v-on:changed="onPerodChanged"></DatePeriodPicker>


    <v-container fluid class="d-flex flex-wrap pa-0 mt-2 ">

      <v-card class="pa-2 mb-2 mr-2" height="70" width="200">
        <v-text-field
            v-model="id_filter"
            label="ID"
            outlined
            clearable
            type="number"
            max="2147483647"
            oninput="validity.valid||(value='');"

        ></v-text-field>
      </v-card>

      <v-card class="pa-2 mb-2 mr-2" height="70" width="200">
        <CurrenciesCombobox v-model="currency_filter"></CurrenciesCombobox>
      </v-card>

      <!--v-card class="pa-2 mb-2 mr-2" height="70" width="200">
        <CurrencySelect v-on:changed="currencychanged"></CurrencySelect>
      </v-card>

      <v-card class="pa-2 mb-2 mr-2" height="70" width="200">
        <BankCombobox3   v-bind:bank.sync="bank"    ></BankCombobox3>
      </v-card-->




      <v-card class="pa-2 mb-2 mr-1" height="70" width="300">
        <v-radio-group row dense v-model="status_filter"   class="pa-0  ma-0" >
          <v-radio label="Только успешные" value="1"></v-radio>
          <v-radio label="Все" value="2"></v-radio>
          <v-radio label="Только отмены" value="3"></v-radio>
        </v-radio-group>
      </v-card>

    </v-container>



    <div class="text-center pt-2">
      <v-pagination    v-model="page"  :length="pageCount"  :total-visible="15" ></v-pagination>
      <ItemsPerPage v-model="itemsPerPage"  :totalRows="totalRows" :pages="[3, 5, 10]"></ItemsPerPage>
    </div>




    <!--v-container-- fluid class="d-flex flex-wrap pa-0 mt-2 ">

      <v-card class="pa-2 mb-2 mr-2" height="70" width="300">
        <DirectRefillDialog v-on:ok="directRefill"></DirectRefillDialog>
      </v-card>

      <v-card class="pa-2 mb-2 mr-2" height="70" width="300">
        <ChatIDInput v-bind:text.sync="chatid"></ChatIDInput>

      </v-card>

    </v-container-->


    <div>
      <v-data-table
          dense
          :items="transactions"
          :headers="headers"

          class="elevation-1 mt-3"
          sort-by="id"
          sort-desc
          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >


        <template v-slot:item.updatedAt="{ item }">
          <DateTimeCell :value="item.updatedAt"></DateTimeCell>
        </template>

        <template v-slot:item.fiat_amount="{ item }"> {{ currency(item.fiat_amount) }}</template>



        <template v-slot:item.cryptocurrency_amount="{ item }"> {{ currency(item.cryptocurrency_amount) }}</template>
        <template v-slot:item.currency_ticker="{ item }"> {{ item.cryptocurrency_ticker }}</template>

        <template v-slot:item.fiat_amount="{ item }"> {{ currency(item.fiat_amount) }}</template>
        <template v-slot:item.fiat_ticker="{ item }"> {{ item.fiat_ticker }}</template>

        <template v-slot:item.user_id="{ item }"> {{ item.user_id + " : " + item.user?.username }}</template>

        <!--template v-slot:item.id="{ item }"> {{ item.id }}</template>

        <template v-slot:item.status="{ item }">

          <v-badge v-if="spam(item)"
                   content="spam?!"
              color="red"
              icon="mdi-alert-circle"
                   inline

          >
            <StatusCell :item="item"/>
          </v-badge>

          <StatusCell v-else :item="item"/>


        </template>
        <template v-slot:item.updatedAt="{ item }">
          <DateTimeCell :value="item.updatedAt"></DateTimeCell>
        </template>

        <template v-slot:item.amount="{ item }">
          <FinanceCell :value="item.offer.amount"/>
          <span> {{ get$ByCountry2(item) }}</span>

        </template>

        <template v-slot:item.cardNumber="{ item }"> {{ item.card ? item.card.number : '--' }}</template>
        <template v-slot:item.cardOwner="{ item }"> {{ item.card ? item.card.owner : '--' }}</template>
        <template v-slot:item.chatId="{ item }">
          {{ item.card ? (item.card.chatId !== null ? item.card.chatId : 'null') : '--' }}
        </template>


        <template v-slot:item.userName="{ item }"> {{ item.user ? item.user.username : '--' }}</template>
        <template v-slot:item.bank="{ item }"> {{ item.bank ? item.bank.name : '--' }}</template>

        <template v-slot:item.fee="{ item }"> {{ item.offer.fee }}</template>
        <template v-slot:item.rate="{ item }"> {{ item.offer.rate }}</template>
        <template v-slot:item.currency="{ item }"> {{ item.currency }}</template>

        <template v-slot:item.info="{ item }"> {{
            item.info ? (item.info.card ? item.info.card : "-") : "--"
          }}
        </template>
 </template>

        <template-- v-slot:item.actions="{ item }">

          <v-btn v-if="item.status!=='canceled'  " class="green mr-1  mb-1"   @click="action(item,'success' )">Одоб  </v-btn>
          <v-btn v-if="item.status!=='canceled'  " class="red mr-1  mb-1"   @click="action(item,'canceled' )">Отмена  </v-btn>
          <v-btn v-if="item.status!=='canceled'  " class="red mr-1  mb-1"   @click="action(item,'wrong_sum' )">Неверная сумма  </v-btn>

        </template-->





      </v-data-table>
    </div>
  </div>
</template>
<script>

//import NumberEditDialog from "./dialogs/NumberEditDialog.vue"
//import CardSelectDialog from "./dialogs/CardSelectDialog.vue"
import gql from "graphql-tag";
import {getHeaders} from "../store";
//import StatusCell from "./controls/StatusCell.vue";
import DateTimeCell from "./controls/DateTimeCell.vue";
//import FinanceCell from "./controls/FinanceCell.vue";

import {fetcher} from "../store";
import Snackbar from "./controls/Snackbar.vue";
//import DirectRefillDialog from "./dialogs/DirectRefillDialog.vue";
//import ChatIDInput from "./controls/ChatIDInput.vue";
import currency from "currency.js";
import DatePeriodPicker from "./controls/DatePeriodPicker.vue";
import ItemsPerPage from "./controls/ItemsPerPage.vue";
//import UserCombobox2 from "./controls/UserCombobox2.vue";

import CurrenciesCombobox from "./controls/CurrenciesCombobox.vue";
//import WalletCombobox from "./controls/WalletsCombobox.vue";


export default {
  name: 'TransactionsMerchant',

  components: {
   // WalletCombobox,
    CurrenciesCombobox,

  //  UserCombobox2,
    ItemsPerPage,
    DatePeriodPicker,
   // ChatIDInput,
    //FinanceCell,
    DateTimeCell, //StatusCell, NumberEditDialog, CardSelectDialog,
    Snackbar, //DirectRefillDialog
  },//DatePeriodPicker

  apollo: {
   // $subscribe: {
      transactions: {
        query: gql`
        query transactions
         ( $exp: transactions_bool_exp! = {}, $lim: Int = 50, $ofs: Int = 0)

         {
  transactions(
    where:  $exp
    order_by: {id: desc}, limit: $lim, offset: $ofs
  )

  {
    id
    external_transaction_id
    cryptocurrency_amount
    cryptocurrency_ticker
    fiat_amount
    fiat_ticker
    issued_wallet
    user_id
    status
    cancel_reason
    createdAt

    updatedAt
    user {
      username
    }

  }

  transactions_aggregate(where: $exp, order_by: {id: desc}) {
    aggregate {
      count
    }
  }


}

`,

        variables() {
//---------------------------- touch only {
          let id_exp = this.id_filter ? {id: {_eq: this.id_filter}} : {};

          //let status_exp = {status: {_nin: ["Pending","client_transfered","offer"]}}

          let status_exp = {}
          switch (this.status_filter) {
            case "2":
              status_exp = {status: {_eq: "completed"}}
              break
            case "3":
              status_exp = {status: {_eq: "canceled"}}
              break

            default:
              status_exp = {status: {_nin: ["Pending","client_transfered","offer"]}}
          }

          let bank_exp = this.bank ? {bank: {name: {_ilike: `%${this.bank.name}%`}}} : {}
          let user_exp = this.user_filter ? {user: {username: {_ilike: `%${this.user_filter.username}%`}}} : {}
          let card_exp = this.card ? {card: {number: {_ilike: `%${this.card}%`}}} : {}
          let country_exp = this.country ? {bank: {country: {_eq: `${this.country}`}}} : {}
          let currency_exp = this.currency_filter ?  {cryptocurrency_ticker: {_eq: `${this.currency_filter.ticker}`}} : {}

          let wallet_exp = this.wallet_filter ?  {issued_wallet: {_eq: `${this.wallet_filter.id}`}} : {}


          let period_exp = this.from&&this.to ? { updatedAt: {_gte: `${this.from}`, _lte: `${this.to}`} } : {}
//---------------------------- }
//do not touch   :
          let exp = {_and: [status_exp, id_exp, bank_exp, user_exp, card_exp, country_exp, period_exp, currency_exp, wallet_exp ]}

          return {
            lim: this.itemsPerPage,
            ofs: (this.page - 1) * this.itemsPerPage,
            exp: exp,
          }
        },

        result({data}) {
          this.transactions = data.transactions;

          this.computeSumm();
          this.totalRows = data.transactions_aggregate.aggregate.count;

        },

      },
    },
  //},

  data: () => ({

    itemsPerPage: 30,
    totalRows: 0,
    page: 1,

    status_filter:"1",
    id_filter:null,
    user_filter:null,
    currency_filter:null,
    wallet_filter:null,

    chatid: "",

    from: "2024-01-01",
    to:  "2024-12-31",

    filterByDatePeriod: false,

    transactions: [],

    sumRub: 0,
    sumUsd: 0,
    sumUsdt: 0,

    headers: [

      {"text": "ID", "value": "id"},

      {"text": "extID", "value": "external_transaction_id"},

      {"text": "Status", "value": "status"},

      {"text": "updatedAt", "value": "updatedAt"},

      {"text": "Cryptocurrency Amount", "value": "cryptocurrency_amount"},
      {"text": "currency_ticker", "value": "currency_ticker"},

      {"text": "Fiat Amount", "value": "fiat_amount"},
      {"text": "fiat_ticker", "value": "fiat_ticker"},


      {"text": "External Transaction ID", "value": "external_transaction_id"},



      {"text": "Issued Wallet", "value": "issued_wallet"},
      {"text": "User ID", "value": "user_id"},


      {"text": "Cancel Reason", "value": "cancel_reason"},

      {text: 'Действия', value: 'actions'},
    ],
  }),

  computed: {

    username() {
      return this.$store.state.user.username
    },

    pageCount() {
      return this.totalRows ? Math.trunc(this.totalRows / this.itemsPerPage) + 1 : 0
    },
  },

  methods: {

    currency,

    computeSumm() {

      let sumRub = 0;
      let sumUsd = 0;
      let sumUsdt = 0;

      this.transactions.forEach(
          t => {
            if (t.status === 'completed') {
              sumRub += this.calcRubAmount(t)
              sumUsd += this.calcUsdAmount0(t)
              sumUsdt += this.usdt0(t)
            }
          }
      );

      this.sumRub = sumRub
      this.sumUsd = sumUsd
      this.sumUsdt = sumUsdt
    },



    onPerodChanged(val) {
      this.from = val.from
      this.to = val.to
    }
    ,


    spam(item) {
      return item.status === 'proof' && (new Date(item.updatedAt) - new Date(item.createdAt)) < 10000
    },

    get$ByCountry2(item) {

      return this.get$ByCountry(item.bank.country)

    },
        dosnackbar(text) {
          this.$refs.snackbar.showit(text)
        },
    get$ByCountry(country) {

      switch (country) {
        case "ru" :
          return "₽"
        case "en" :
          return "$"
        case "tr" :
          return "₺"
        case "kz" :
          return "₸"
        case "uz" :
          return "UZS"

        default:
          return "def$"
      }

    },

    getCountry() {
      return this.$store.state.user.country
    },

/*
    async directRefill(user, card, sum) {


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
            {
              merchantId: +(user.id),
              cardId: +(card.id),
              amount: +sum
            })
      };

      let url = `${RestApiUrl}`

      fetcher(this.dosnackbar, url, requestOptions)


    },



    onPerodChanged(val) {
      //  console.log('onPerodChanged', val)
      this.from = val.from
      this.to = val.to
    },

    usdt(item) {
      //  console.log(item.offer)
      return (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0
    },

    async card(id, card) {
      // Выдать карту
      // app.patch("/api/admin/transaction/:id/offer"
      // req.body.cardId - id карты (int)

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({cardId: card.id})
      };

      let url = `${RestApiUrl}/${id}/offer`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async amount(id, amount) {
      //Изменить сумму
      //app.patch("/api/admin/transaction/:id/amount"
      //req.body.amount - сумма в рублях (float)

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({amount: amount})
      };

      let url = `${RestApiUrl}/${id}/amount`

      fetcher(this.dosnackbar, url, requestOptions)
    },*/

    async action(item, status) {

      if (!window.confirm(`Подтвердите новый статус ?!
          ID: ${item.id};
          Статус: ${status};
          `)) {

        return
      }

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
            {"id":item.id, "status":status}
        )
      };

      let url = `${process.env.VUE_APP_REST_API_URL_ADMIN}/acception` ///${item.id}

      fetcher(this.dosnackbar, url, requestOptions)
    },
/*
    async del(item) {
      //Удаление заявки
      // app.delete("/api/admin/transaction/:id"


      if (!window.confirm(`Подтвердите УДАЛЕНИЕ ? \n ID: ${item.id};
        Сумма: ${item.offer.amount};
        Мерчант: ${item.user ? item.user.username : '--'};
        Банк: ${item.bank ? item.bank.name : '--'};


            `)) {

        return
      }


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'DELETE',
        headers: headers,
        //body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    async save(item, mode) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: mode === "new" ? 'POST' : 'PATCH',
        headers: headers,
        body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${mode === "new" ? "" : item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },
*/

    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },
  },

}
</script>
