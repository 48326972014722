var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          label: _vm.selectLabel,
          items: _vm.items,
          "item-text": (item) => _vm._getItemText(item),
          "return-object": "",
          outlined: "",
          clearable: "",
        },
        on: { change: _vm.change },
        model: {
          value: _vm.value2,
          callback: function ($$v) {
            _vm.value2 = $$v
          },
          expression: "value2",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }