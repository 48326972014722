<template>

  <v-select
      v-model="id"
      label="Wallet"
      :items="items"

      item-value="id"
      :item-text="item => item.network.name + ' - ' + item.wallet_address "
      return-object
      outlined
      clearable

      @change="change"
  ></v-select>

</template>

<script>

import gql from "graphql-tag";

export default {
  name: "WalletCombobox",//change it

  data: function () {
    return {
      items: [],
      ticker: this.value,
    }
  },

  props: ['value'],

  apollo: {
    //$subscribe: {  where: {role: {_eq: "merchant"}}
    wallets: {///1 change query name   cryptocurrencies and change body
      query: gql`
{
  wallets(order_by: {network: {id: asc}, id: asc}) {
    id
    network {
      name
      id
    }
    wallet_address
  }
}
`,
      result({data}) {
        this.items = data.wallets;///2 replace cryptocurrencies
      },
    },
    //  },
  },

  methods: {
    change(val) {
      this.$emit('input', val)//return   full user object, and then in filter   use field
    }
  },
}
</script>

