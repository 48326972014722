<template>
  <div class="pa-2">

    <div class="Transactions">
      <h3>Транзакции - Noros payments {{terminal}} </h3>
    </div>

    <div>


      <TransactionsMerchant ></TransactionsMerchant>
    </div>
  </div>

</template>
<script>

//import TransactionsUsd from "../components/TransactionsUsd";
import TransactionsMerchant from "./TransactionsMerchant.vue";

export default {
  name: 'Transactions',

  components: {
    TransactionsMerchant,
     //TransactionsUsd
  }
  ,

  data: () => ({

  }),

  computed: {
    terminal() {
      return this.$store.state.user.terminal
    }
    ,
  }
  ,

  methods: {}
  ,

}
</script>
