import Vue from 'vue'
import Vuex from 'vuex'
//import {authentication} from './authentication.module';
import {auth} from './auth.module';
import {user} from './user.module';
import {parseJwt, getCurrentTimeUTC} from "./parseJwt";

export async function fetcher(dosnackbar, url, requestOptions, raw=false) {
    //принимает проверенный токен!!!

  /*  let accessToken = store.state.auth.credentials.accessToken
    let pt = parseJwt(accessToken);
    let t = pt.exp - getCurrentTimeUTC()*/



    function fetcher(raw=false) {
        fetch(url, requestOptions)
            .then((response) => {

                    if (response.status !== 200 && response.status !== 201) {  // if error
                        response.json()
                            .then(function (data) {

                                dosnackbar(`Ошибка ${response.status}; ${data.message}`)
                            })
                            .catch(function (data) {
                                console.log(data)
                                dosnackbar(`Ошибка ${response.status}`)
                            })
                        return;
                    }

                    if(raw)
                        response.text().then( t=>dosnackbar(t,raw) )
                    else

                    response.json().then(function (data) {
                        console.debug(data)
                        dosnackbar(`Успех`)
                    });
                }
            )
            .catch(function (err) {
                dosnackbar(`Ошибка Fetch Error`)
                console.log('Fetch Error', err);
            });
    }

   /* if (t < 100) {  //TODO: duplicate code  in  router
        store.dispatch('auth/onRefreshToken', store.state.auth.credentials.refreshToken)
            .then(() => {
                fetcher();
            })
            .catch((reason) => {//TODO: внутри onRefreshToken уже есть проверка на ошибку
                console.log(reason)
            })
    }else*/
        fetcher(raw);

}

Vue.use(Vuex)

export async function getHeaders() {
    return {
        Authorization: await getToken()
    }
}


//export function getToken() {
 //   let r = getToken3()
 //   return    r
//}



 export async function getToken() {

    let accessToken = store.state.auth.credentials.accessToken
    let pt = parseJwt(accessToken);
    let t = pt.exp - getCurrentTimeUTC()

    if (t < 100) {  //TODO: duplicate code  in  router  (token expired )
        try {
            await store.dispatch('auth/onRefreshToken', store.state.auth.credentials.refreshToken)

            return "Bearer " + store.state.auth.credentials.accessToken
        }catch (reason){
            console.log(reason)
        }
    }
    else
        return "Bearer " + store.state.auth.credentials.accessToken

}


let store = new Vuex.Store({

    modules: {user, auth,},

    state: {
        //  user: {},
    },

    getters: {},

    mutations: {

        reset: () => {
        },

        // RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION
    },
    actions: {}
})

export default store