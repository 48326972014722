var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ staticClass: "indigo ma-3", attrs: { text: "" } }, on),
                [
                  _vm._v(
                    " " + _vm._s(_vm.mode == "new" ? "создание" : "правка")
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v(
              "Wallet - " + _vm._s(_vm.mode == "new" ? "создание" : "правка")
            ),
          ]),
          _c(
            "div",
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "10" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Тикер",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.ticker,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "ticker", $$v)
                                  },
                                  expression: "item.ticker",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "state",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "state", $$v)
                                  },
                                  expression: "item.state",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "wallet_address",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.wallet_address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "wallet_address", $$v)
                                  },
                                  expression: "item.wallet_address",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "wallet_balance",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.wallet_balance,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "wallet_balance", $$v)
                                  },
                                  expression: "item.wallet_balance",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "wallet_comment",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.wallet_comment,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "wallet_comment", $$v)
                                  },
                                  expression: "item.wallet_comment",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "min_payment_amount",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.min_payment_amount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.item,
                                      "min_payment_amount",
                                      $$v
                                    )
                                  },
                                  expression: "item.min_payment_amount",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "max_payment_amount",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.max_payment_amount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.item,
                                      "max_payment_amount",
                                      $$v
                                    )
                                  },
                                  expression: "item.max_payment_amount",
                                },
                              }),
                              _c("SelectNetwork", {
                                attrs: { val: _vm.item.network_id },
                                on: {
                                  "update:val": function ($event) {
                                    return _vm.$set(
                                      _vm.item,
                                      "network_id",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Отмена")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.ok },
                },
                [_vm._v(" Ок")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }