import Vue from 'vue'
import VueRouter from 'vue-router'
import Transactions from '../views/Transactions.vue'
import TransactionsAdminArchive from '../components/TransactionsAdminArchive.vue'
import Withdraws from '../views/Withdraws.vue'
import Payouts from '../views/Payouts.vue'
import Login from '../views/Login.vue'
import Cards from '../views/Cards.vue'
import Banks from '../views/Banks.vue'
import store from '../store'
import HistoryView from '../views/HistoryView.vue'
import {getCurrentTimeUTC, parseJwt} from "../store/parseJwt";

Vue.use(VueRouter) //for templates

const routes = [
    {
        path: '/',
        redirect: '/transactions',
        //name: 'Home',
        //component: Home
        meta: {title: 'Noros payments'}
    },

    {
        path: '/transactions',
        name: 'Transactions',
        component: Transactions,
        meta: {title: 'Транзакции - Noros payments'}
    },

    {
        path: '/transactions_archive',
        name: 'Transactions archive',
        component: TransactionsAdminArchive,
        meta: {title: 'Архив транзакций - Noros Payments'}
    },

    {
        path: '/withdraws',
        name: 'Withdraws',
        component: Withdraws,
        meta: {title: 'Вывод средств - Noros payments'}
    },

    {
        path: '/payouts',
        name: 'Payouts',
        component: Payouts,
        meta: {title: 'Выплаты - Noros Payments'}
    },

    {
        path: '/wallets',
        name: 'Wallets',
        component: Cards,
        meta: {title: 'Wallets'}
    },

    {
        path: '/cardsbalances',
        name: 'CardsBalances',
        component: () => import( '../views/CardsBalances.vue'),
        meta: {title: 'Баланс карт - Noros Payments'}
    },

    {
        path: '/banks',
        name: 'Banks',
        component: Banks,
        meta: {title: 'Банки - Noros Payments'}
    },

    {
        path: '/login',
        name: 'Login',
        component: Login,//() => import( '../views/Login.vue')
        meta: {title: 'Вход в мерчант-аккаунт - Noros payments'}
    },

    {
        path: '/users',
        name: 'Users',
        component: () => import( '../views/Users.vue'),
        meta: {title: 'Юзеры - Noros Payments'}
    },




    {
        path: '/cash',
        name: 'Cash',
        component: () => import( '../views/Cash.vue'),
        meta: {title: 'Купить за наличные - Noros Payments'}
    },

    {
        path: '/cashSell',
        name: 'cashSell',
        component: () => import( '../views/CashSell.vue'),
        meta: {title: 'Продать за наличные - Noros Payments'}
    },

    {
        path: '/cashArchive',
        name: 'cashArchive',
        component: () => import( '../views/CashArchive.vue'),
        meta: {title: 'Архив наличных - Noros Payments'}
    },




    {
        path: '/exchangeUsers',
        name: 'ExchangeUsers',
        component: () => import( '../views/ExchangeUsers.vue'),
        meta: {title: 'Обменник юзеры - Noros Payments'}
    },

    {
        path: '/exchangeUsersVerify',
        name: 'ExchangeUsersVerify',
        component: () => import( '../views/ExchangeUsersVerify.vue'),
        meta: {title: 'Обменник верификация - Noros Payments'}
    },

    {
        path: '/exchangeHistory',
        name: 'ExchangeHistory',
        component: () => import( '../views/ExchangeHistory.vue'),
        meta: {title: 'Конвертации - Noros Payments'}
    },


    {
        path: '/exchangeConclusion',
        name: 'ExchangeConclusion',
        component: () => import( '../views/ExchangeConclusion.vue'),
        meta: {title: 'Обменник выводы - Noros Payments'}
    },

    {
        path: '/exchangeConclusionHistory',
        name: 'ExchangeConclusionHistory',
        component: () => import( '../views/ExchangeConclusionHistory.vue'),
        meta: {title: 'Обменник архив выводов - Noros Payments'}
    },

    {
        path: '/exchangeDeposits',
        name: 'ExchangeDeposits',
        component: () => import( '../views/ExchangeDeposits.vue'),
        meta: {title: 'Обменник депозиты - Noros Payments'}
    },


    {
        path: '/exchangeDepositsHistory',
        name: 'ExchangeDepositsHistory',
        component: () => import( '../views/ExchangeDepositsHistory.vue'),
        meta: {title: 'Обменник архив депозитов - Noros Payments'}
    },

    {
        path: '/exchangeDepositInvoice',
        name: 'ExchangeDepositInvoice',
        component: () => import( '../views/ExchangeDepositInvoice.vue'),
        meta: {title: 'Депозиты - Счета - Noros Payments'}
    },




    {
        path: '/exchangeConfig',
        name: 'ExchangeConfig',
        component: () => import( '../views/ExchangeConfig.vue'),
        meta: {title: 'Обменник дешбоард - Noros Payments'}
    },


    {
        path: '/exchangeTransactionsArchiveFull',
        name: 'ExchangeTransactionsArchiveFull',
        component: () => import( '../views/ExchangeTransactionsArchiveFull.vue'),
        meta: {title: 'Архив покупок - Noros Payments'}
    },

    {
        path: '/exchangePayoutsUser',
        name: 'ExchangePayoutsUser',
        component: () => import( '../views/ExchangePayoutsUser.vue'),
        meta: {title: 'Продажа - обменник - Noros Payments'}
    },


    {
        path: '/historyAll',
        name: 'HistoryAll',
        component: () => import( '../views/HistoryAll.vue'),
        meta: {title: 'История - Noros Payments'}
    },

    {
        path: '/config',
        name: 'Config',
        component: () => import( '../views/UserConfig.vue'),
        meta: {title: 'Настройки - Noros Payments'}
    },

    {
        path: '/history/:cardId',
        name: 'History',
        component: HistoryView,
        meta: {title: 'История коррекций - Noros Payments'},
        props: true
    },


    {
        path: '/history',
        name: 'History',
        component: HistoryView,
        meta: {title: 'История коррекций - Noros Payments'},
        props: true
    },

]

const path = process.env.VUE_APP_BASE_PATH



const router = new VueRouter({
    base: path,//'/admin',
    routes
})

router.beforeEach(async (to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page

    //token exists?
    let accessToken = store.state.auth.credentials.accessToken

    if (!accessToken)   // no accessToken ?  redirect  to login
        return to.name !== "Login" ? next('/login') : next();

    let pt = parseJwt(accessToken);
    let t = pt.exp - getCurrentTimeUTC()


    async function onTokenOk() {
        // accessToken  is ok

        if (!store.state.user.userDataLoaded)  //load user if not loaded
            await store.dispatch('user/onUserFetchRequest', pt.id, {root: true});

        ///if (to.name === "Login") // authed and try open login page? redirect to trans
        ///    return next(store.state.user.role==="exch_operator" ? '/exchangeUsers': '/transactions');

        document.title = to.meta.title;

        next();
    }

    if (t < 100) { //  accessToken exists  but expired, try refresh it
        store.dispatch('auth/onRefreshToken', store.state.auth.credentials.refreshToken)
            .then(async () => {
                console.log("refresh token ok"); //refreshing -  ok
                return await onTokenOk();
            })
            .catch((reason) => { //TODO: внутри onRefreshToken уже производится логаут при ошибке, тут дублирование
                console.error(reason);
                store.dispatch('auth/onLogout', '', {root: true});
            })
    }
    else // token not expired, next step
        await onTokenOk();

})

export default router

