<template>

  <v-container fill-height fluid>
    <v-row
    >
      <v-col
          align="center"
          justify="center"
      >

        <!--v-img
            class="mb-10"
            max-height="220"
            max-width="132"
            :src="myImage"
        ></v-img-->

        <div><span>recrypto.me</span></div>


        <h3>Вход в аккаунт</h3>

        <LoginPage></LoginPage>


        <div><span>Нет аккаунта? Напишите нам - sales@recrypto.me</span></div>

        <div class="mt-10">
        <!--v-btn

            href="https://t.me/noros_support" target="_blank">
          Поддержка 24/7
          <v-icon>mdi-send-circle</v-icon>
        </v-btn-->
        </div>

      </v-col>
    </v-row>
  </v-container>


</template>

<script>

import LoginPage from '../components/LoginPage.vue'

export default {
  name: 'Login',

  components: {
    LoginPage
  },

  data: () => ({
    myImage: require('@/logo.svg'),
  }),

}
</script>
