<template>

  <v-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <v-btn class="indigo ma-3" text v-on="on"> {{ mode == "new" ? "создание" : "правка" }}</v-btn>
    </template>

    <v-card>
      <v-card-title primary-title>Wallet - {{ mode == "new" ? "создание" : "правка" }}</v-card-title>

      <div>

        <v-form>
          <v-container>
            <v-row>
              <v-col cols="10">

                <v-text-field v-model="item.ticker" label="Тикер" outlined clearable></v-text-field>
                <v-text-field v-model="item.state" label="state" outlined clearable></v-text-field>
                <v-text-field v-model="item.wallet_address" label="wallet_address" outlined clearable></v-text-field>
                <v-text-field v-model="item.wallet_balance" label="wallet_balance" outlined clearable></v-text-field>
                <v-text-field v-model="item.wallet_comment" label="wallet_comment" outlined clearable></v-text-field>
                <v-text-field v-model="item.min_payment_amount" label="min_payment_amount" outlined clearable></v-text-field>
                <v-text-field v-model="item.max_payment_amount" label="max_payment_amount" outlined clearable></v-text-field>
                <SelectNetwork v-bind:val.sync="item.network_id" ></SelectNetwork>

              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Отмена</v-btn>
        <v-btn color="primary" text @click="ok"> Ок</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>


//import BankCombobox from "../controls/BankCombobox.vue";
//import ChatIDInput from "../controls/ChatIDInput.vue";

import SelectNetwork from "../controls/SelectNetwork.vue";

export default {
  name: 'CardsEditDialog',
  components: {SelectNetwork},
  data: function () {
    return {
      dialog: false,

      emptyItem: {
        ticker: "",
        state: "",
        wallet_address: "",
        wallet_balance: "",
        wallet_comment: "",
        min_payment_amount: "",
        max_payment_amount: "",
        network_id: 0,
      },
    }
  },

  props: ['item', 'mode'],
  /*watch: {
    dialog: function (val) {
      if (val) {
        if (!this.item.tg) {
          this.item.tg = {id: "", link: ""}
        }
      }
    },
  },*/

  methods: {
    extractNumbersFromString: function (inputString) {
      // Используем регулярное выражение для поиска чисел в строке
      const numberRegex = /\d+/g;

      // Используем метод match() для нахождения всех чисел в строке
      const numbers = inputString.match(numberRegex);

      // Возвращаем массив чисел
      return numbers ? numbers.map(Number) : [];
    },


    ok: function () {

      /*if (this.item.max < 0 || this.item.min < 0) {
        alert("Ошибка! Укажите мин и макс больше или равно 0")
        return
      }

      if (this.item.max < this.item.min) {
        alert("Ошибка! мин больше макс")
        return
      }

      if (!this.item.limit || this.item.limit < 0) {
        alert("Ошибка! дневой лимит меньше нуля")
        return
      }

      if (!this.item.chatId || this.item.chatId <= 0) {
        alert("Ошибка! ИД чата должен быть положительным числом")
        return
      }*/


      this.dialog = false;
      this.$emit('ok', this.item, this.mode)
    }
  },

}
</script>
