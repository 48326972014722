<template>

  <div class="ma-2">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Transactions ma-2">
      <h3>Открытые транзакции - operator</h3>
    </div>

    <v-container fluid class="d-flex flex-wrap pa-0 mt-2 ">

      <v-card class="pa-2 mb-2 mr-2" height="70" width="300">
        <DirectRefillDialog v-on:ok="directRefill"    ></DirectRefillDialog>
      </v-card>

      <v-card class="pa-2 mb-2 mr-2" height="70" width="300">
        <ChatIDInput  v-bind:text.sync="chatid"  ></ChatIDInput>

      </v-card>

    </v-container>



    <div>
      <v-data-table
          dense
          :items="transactions"
          :headers="headers"
          class="elevation-1 mt-3"
          sort-by="id"
          sort-desc
          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.status="{ item }">
          <StatusCell :item="item"/>
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <DateTimeCell :value="item.updatedAt"></DateTimeCell>
        </template>

        <template v-slot:item.amount="{ item }">
          <FinanceCell :value="item.offer.amount"   />
          <span> {{ get$ByCountry2(item) }}</span>
        </template>

        <template v-slot:item.cardNumber="{ item }"> {{ item.card ? item.card.number : '--' }}</template>
        <template v-slot:item.cardOwner="{ item }"> {{ item.card ? item.card.owner : '--' }}</template>
        <template v-slot:item.userName="{ item }"> {{ item.user ? item.user.username : '--' }}</template>
        <template v-slot:item.bank="{ item }"> {{ item.bank ? item.bank.name : '--' }}</template>

        <template v-slot:item.fee="{ item }"> {{ item.offer.fee }}</template>
        <template v-slot:item.rate="{ item }"> {{ item.offer.rate }}</template>

        <template v-slot:item.currency="{ item }"> {{ item.bank ? get$TickerByCountry(item.bank.country)  : "" }}</template>

        <template v-slot:item.chatId="{ item }"> {{ item.card ? (item.card.chatId !== null ? item.card.chatId : 'null' ) : '--' }}</template>

        <template v-slot:item.info="{ item }"> {{ item.info ? (item.info.card?item.info.card:"-" )  : "--"  }}</template>


        <template v-slot:item.actions="{ item }">

          <v-btn class="green mr-1  mb-1" v-if="item.status==='accepted' || item.status==='proof' || item.status==='offer' "      dense text
                 @click="approve(item)">Одоб
          </v-btn>

          <v-btn dense class="red mr-1 mb-1" text @click="del(item)">Удал</v-btn>

          <CardSelectDialog v-if="item.status==='created' " caption="Выд.карту" v-bind:tag="item.id"
                            v-on:ok="card"></CardSelectDialog>

          <NumberEditDialog v-if="item.status==='accepted' || item.status==='proof' || item.status==='offer' " caption="Правк"
                            title="Правка суммы" label="Сумма "
                            v-bind:tag="item.id" v-bind:value="item.offer.amount" v-on:ok="amount"></NumberEditDialog>


        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>

import NumberEditDialog from "./dialogs/NumberEditDialog.vue"
import CardSelectDialog from "./dialogs/CardSelectDialog.vue"
import gql from "graphql-tag";
import {getHeaders} from "../store";
import StatusCell from "./controls/StatusCell.vue";
import DateTimeCell from "./controls/DateTimeCell.vue";
import FinanceCell from "./controls/FinanceCell.vue";

const RestApiUrl = process.env.VUE_APP_REST_API_URL_ADMIN + "/transaction"
import {fetcher} from "../store";
import Snackbar from "./controls/Snackbar.vue";
import DirectRefillDialog from "./dialogs/DirectRefillDialog.vue";
import ChatIDInput from "./controls/ChatIDInput.vue";

export default {
  name: 'TransactionsOperator',

  components: {
    ChatIDInput,
    FinanceCell, DateTimeCell, StatusCell, NumberEditDialog, CardSelectDialog, Snackbar, DirectRefillDialog},//DatePeriodPicker

  apollo: {

    $subscribe: {
      transactions: {//,limit: 200
        query: gql`
subscription s($country: String!,  $exp: transactions_bool_exp! = {} ) {
  transactions(
  where:
    { _and:[
        { status:{_nin: ["completed","canceled"],}} ,
        $exp,
        { bank: {country: {_eq: $country}} }
        ]
    ,}


  , limit: 200, order_by: {id: desc}) {
    id
    updatedAt
    uid
    merchantId
    status
    offer
    createdAt
    currency
    amount
    info

    card {
      number
      owner
      chatId
    }
    user {
      username
    }
    bank {
      name
      country
    }
  }
}


`,


        variables() {
          let card_exp = (this.chatid)&&(!isNaN(Number(this.chatid))) ? {card: {chatId: {_eq: `${this.chatid}`}}} : {}

          return {

            country : this.getCountry(),

            exp : {_and: [ card_exp, ]}
          }
        },


        result({data}) {
          this.transactions = data.transactions;
        },

      },
    },
  },

  data: () => ({

    chatid:"",

    filterByDatePeriod: false,

    transactions: [],


    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Статус', value: 'status'},
      {text: 'Время', value: 'updatedAt'},
      {text: 'Cумма', value: 'amount'},
      //{text: 'Валюта', value: 'currency'},
      {text: 'Карта', value: 'cardNumber'},
      {text: 'ChatId', value: 'chatId'},
      {text: 'Владелец', value: 'cardOwner'},
      {text: 'Мерчант', value: 'userName'},
      {text: 'Банк', value: 'bank'},
      {text: 'Карта отправителя', value: 'info'},
      {text: 'Действия', value: 'actions'},
    ],
  }),


  methods: {

    get$ByCountry2(item){

      return this.get$ByCountry(item.bank.country)

    },




    get$TickerByCountry(country){

      switch (country){
        case "ru" : return "RUB"
        case "en" : return "USD"
        case "tr" : return "TRY"

        default:
          return "default"
      }

    },

    get$ByCountry(country){

      switch (country){
        case "ru" : return "₽"
        case "en" : return "$"
        case "tr" : return "₺"

        default:
          return "def$"
      }

    },

    getCountry() {
      return this.$store.state.user.country
    },

   async directRefill(user, card,sum){
      /* POST
      /api/admin/transaction
     merchantId
     cardId
     amount */

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
            {
              merchantId:+(user.id),
              cardId:+(card.id),
              amount:+sum
            })
      };

      let url = `${RestApiUrl}`

      fetcher(this.dosnackbar, url, requestOptions)


    },

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    onPerodChanged(val) {
      //  console.log('onPerodChanged', val)
      this.from = val.from
      this.to = val.to
    },

    usdt(item) {
      //  console.log(item.offer)
      return (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0
    },

    async card(id, card) {
      // Выдать карту
      // app.patch("/api/admin/transaction/:id/offer"
      // req.body.cardId - id карты (int)

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({cardId: card.id})
      };

      let url = `${RestApiUrl}/${id}/offer`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async amount(id, amount) {
      //Изменить сумму
      //app.patch("/api/admin/transaction/:id/amount"
      //req.body.amount - сумма в рублях (float)

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({amount: amount})
      };

      let url = `${RestApiUrl}/${id}/amount`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async approve(item) {
      //Одобрение
      //app.put("/api/admin/transaction/:id"


      if (!window.confirm(`Подтвердите Одобрение ?!
ID: ${item.id};
Сумма: ${item.offer.amount};
Мерчант: ${item.user ? item.user.username : '--'};
Банк: ${item.bank ? item.bank.name : '--'};
ИД чата: ${item.card ? item.card.chatId : '--'};

            `)) {

        return
      }

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PUT',
        headers: headers,
        //body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async del(item) {
      //Удаление заявки
      // app.delete("/api/admin/transaction/:id"


      if (!window.confirm(`Подтвердите УДАЛЕНИЕ ? \n ID: ${item.id};
        Сумма: ${item.offer.amount};
        Мерчант: ${item.user ? item.user.username : '--'};
        Банк: ${item.bank ? item.bank.name : '--'};


            `)) {

       return
      }


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'DELETE',
        headers: headers,
        //body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    async save(item, mode) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: mode === "new" ? 'POST' : 'PATCH',
        headers: headers,
        body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${mode === "new" ? "" : item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },
  },

}
</script>
