var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { align: "center", justify: "center" } },
            [
              _c("div", [_c("span", [_vm._v("recrypto.me")])]),
              _c("h3", [_vm._v("Вход в аккаунт")]),
              _c("LoginPage"),
              _c("div", [
                _c("span", [
                  _vm._v("Нет аккаунта? Напишите нам - sales@recrypto.me"),
                ]),
              ]),
              _c("div", { staticClass: "mt-10" }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }