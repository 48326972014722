var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-2" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c("DatePeriodPicker", {
        attrs: { caption: "Период выборки", from: _vm.from, to: _vm.to },
        on: { changed: _vm.onPerodChanged },
      }),
      _c(
        "v-container",
        { staticClass: "d-flex flex-wrap pa-0 mt-2", attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "70", width: "200" },
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: "ID",
                  outlined: "",
                  clearable: "",
                  type: "number",
                  max: "2147483647",
                  oninput: "validity.valid||(value='');",
                },
                model: {
                  value: _vm.id_filter,
                  callback: function ($$v) {
                    _vm.id_filter = $$v
                  },
                  expression: "id_filter",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "70", width: "200" },
            },
            [
              _c("CurrenciesCombobox", {
                model: {
                  value: _vm.currency_filter,
                  callback: function ($$v) {
                    _vm.currency_filter = $$v
                  },
                  expression: "currency_filter",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-1",
              attrs: { height: "70", width: "300" },
            },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "pa-0 ma-0",
                  attrs: { row: "", dense: "" },
                  model: {
                    value: _vm.status_filter,
                    callback: function ($$v) {
                      _vm.status_filter = $$v
                    },
                    expression: "status_filter",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { label: "Только успешные", value: "1" },
                  }),
                  _c("v-radio", { attrs: { label: "Все", value: "2" } }),
                  _c("v-radio", {
                    attrs: { label: "Только отмены", value: "3" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-center pt-2" },
        [
          _c("v-pagination", {
            attrs: { length: _vm.pageCount, "total-visible": 15 },
            model: {
              value: _vm.page,
              callback: function ($$v) {
                _vm.page = $$v
              },
              expression: "page",
            },
          }),
          _c("ItemsPerPage", {
            attrs: { totalRows: _vm.totalRows, pages: [3, 5, 10] },
            model: {
              value: _vm.itemsPerPage,
              callback: function ($$v) {
                _vm.itemsPerPage = $$v
              },
              expression: "itemsPerPage",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1 mt-3",
            attrs: {
              dense: "",
              items: _vm.transactions,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.updatedAt",
                fn: function ({ item }) {
                  return [
                    _c("DateTimeCell", { attrs: { value: item.updatedAt } }),
                  ]
                },
              },
              {
                key: "item.fiat_amount",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(_vm.currency(item.fiat_amount)))]
                },
              },
              {
                key: "item.cryptocurrency_amount",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.currency(item.cryptocurrency_amount))
                    ),
                  ]
                },
              },
              {
                key: "item.currency_ticker",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.cryptocurrency_ticker))]
                },
              },
              {
                key: "item.fiat_ticker",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.fiat_ticker))]
                },
              },
              {
                key: "item.user_id",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(item.user_id + " : " + item.user?.username)
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Transactions ma-2" }, [
      _c("h3", [_vm._v("*Транзакции мерчанта")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }