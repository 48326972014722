var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Select", {
        attrs: {
          selectLabel: _vm.selectLabel,
          getItemText: _vm.getItemText,
          getItemData: _vm.getItemData,
          items: _vm.items,
          val: _vm.val2,
        },
        on: {
          "update:val": function ($event) {
            _vm.val2 = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }