<template>

  <div>

    {{ caption }}

    <v-row>

      <v-col cols="12" sm="6" md="4">
        <DatePicker2 label="От даты" min='2021-01-01' :date="from2" :max="to2" v-on:save="onFrom"></DatePicker2>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <DatePicker2 label="До даты" :min="from2" :date="to2" max='2025-12-31' v-on:save="onTo"></DatePicker2>
      </v-col>

    </v-row>

    <v-btn @click="year">Год</v-btn>
    <v-btn @click="quarter">Этот квартал</v-btn>
    <v-btn @click="month">Этот месяц</v-btn>
    <v-btn @click="week">Эта неделя</v-btn>


    <v-btn @click="yesterday">Вчера</v-btn>
    <v-btn @click="today">Сегодня</v-btn>

  </div>
</template>
<script>

import DatePicker2 from "./DatePicker2.vue"

export default {

  data: () => ({
    from2: "2023-01-01",
    to2: "2023-12-31",
  }),

  name: "DatePeriodPicker",
  disableEmitChanged: false, //костыль от двойного  вызова  евента
  components: {DatePicker2},
  props: ['caption', 'from', 'to'],//TODO  warn    from to  must be  used only  as init  value

  mounted() {
    this.from2 = this.from
    this.to2 = this.to
  },

  methods: {

    onFrom(val) {
      this.$emit('from', val)
      this.from2 = val
      this.emitChanged(val, this.to2)
    },

    onTo(val) {
      this.$emit('to', val)
      this.to2 = val
      this.emitChanged(this.from2, val)
    },


    zeroTime(date){
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0)
      return date;
    },

    lastTime(date){
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      date.setMilliseconds(999)
      return date;
    },

    toUTC(localDate) {
        // Получаем разницу между местным временем и UTC в миллисекундах
      const timezoneOffset = localDate.getTimezoneOffset() * 60 * 1000;
        // Создаем новый объект Date с временем, скорректированным для UTC
      return new Date(localDate.getTime() + timezoneOffset);
    },

    emitChanged(from, to) {

      var firstday = this.zeroTime(new Date(from));
      var isoFirstTime = firstday.toISOString()

      var lastday = this.lastTime(new Date(to));
      //lastday.setDate(lastday.getDate() + 1);
      var isoLastTime = lastday.toISOString()


      this.$emit('changed', {from: isoFirstTime, to: isoLastTime,   from2:from, to2:to})
    },

    emitChanged2(from, to) {

      var lastday = new Date(to);
      lastday.setDate(lastday.getDate() + 1);

      this.$emit('changed', {from: from, to: this.getISODate(lastday)})  //currentDate.setDate(currentDate.getDate() + 1);
    },

    year() {
      this.disableEmitChanged = true
      let year = new Date().getFullYear()

      this.from2 = this.getISODate( Date.parse(`${year}-01-01`) )
      this.to2 = this.getISODate( Date.parse(`${year}-12-31`) )
      this.emitChanged(this.from2, this.to2)
    },


    month() {
      this.disableEmitChanged = true
      //shit
      this.from2 = this.getISODate(new Date((new Date).getFullYear(), (new Date).getMonth(), 1))
      this.to2 = this.getISODate(new Date((new Date).getFullYear(), (new Date).getMonth() + 1, 0))

      //(new Date( new Date((new Date).getFullYear(), (new Date).getMonth(), 1) - (new Date()).getTimezoneOffset() * 60000 )).toISOString().slice(0, 10);  //new Date((new Date).getFullYear(), (new Date).getMonth(), 1)
      //(new Date( new Date((new Date).getFullYear(), (new Date).getMonth() + 1, 0) - (new Date()).getTimezoneOffset() * 60000 )).toISOString().slice(0, 10);
      // new Date((new Date).getFullYear(), (new Date).getMonth() + 1, 0)

      // this.from2 = from.toLocaleDateString()
      // this.to2 = to.toLocaleDateString()
      this.emitChanged(this.from2, this.to2)
    },

    getISODateTime(ISODate, beginDay = true) {
      if(beginDay)
        return (new Date(ISODate).toISOString())
      else
        return (new Date(ISODate).toISOString()  )
    },


    getISODate(date) {
      return (new Date(date - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)
    },

    getISODate2(date) {
      return (new Date(date - (new Date()).getTimezoneOffset() * 60000)).toISOString()
    },

    week() {
      this.disableEmitChanged = true
      const today = new Date();

      //this.from2 =  (new Date( new Date((new Date).getFullYear(), (new Date).getMonth(), 1) - (new Date()).getTimezoneOffset() * 60000 )).toISOString().slice(0, 10);  //new Date((new Date).getFullYear(), (new Date).getMonth(), 1)
      //this.to2 =  (new Date( new Date((new Date).getFullYear(), (new Date).getMonth() + 1, 0) - (new Date()).getTimezoneOffset() * 60000 )).toISOString().slice(0, 10);

      this.from2 = this.getISODate(new Date(today.setDate(today.getDate() - today.getDay() + 1)))
      this.to2 = this.getISODate(new Date(today.setDate(today.getDate() - today.getDay() + 6 + 1 )))

      //this.from2 = new Date(today.setDate(today.getDate() - today.getDay() + 1)).toISOString().slice(0, 10);  //+1 week start
      //this.to2 = new Date(today.setDate(today.getDate() - today.getDay() + 6 + 1)).toISOString().slice(0, 10);

      this.emitChanged(this.from2, this.to2)
    },


    today() {
      this.disableEmitChanged = true
      const today = new Date();

      this.from2 = (this.getISODate(new Date(today)))
      this.to2 = (this.getISODate(new Date(today)))


      // this.from2 = new Date(today).toISOString().slice(0, 10);  //+1 week start
      // this.to2 = new Date(today).toISOString().slice(0, 10);
      this.emitChanged(this.from2, this.to2)
    },


    yesterday() {
      this.disableEmitChanged = true
      const today = new Date();
      const n = new Date()
      n.setDate(today.getDate() - 1)


      this.from2 = this.getISODateTime(this.getISODate(new Date(n)))
      this.to2 = this.getISODateTime(this.getISODate(new Date(n)))


      // this.from2 = new Date(n).toISOString().slice(0, 10);  //+1 week start
      // this.to2 = new Date(n).toISOString().slice(0, 10);
      this.emitChanged(this.from2, this.to2)
    },


    quarter() {
      this.disableEmitChanged = true
      let now = new Date();
      let quarter = Math.floor((now.getMonth() / 3));
      let firstDate = new Date(now.getFullYear(), quarter * 3, 1);


      this.from2 = this.getISODate(firstDate)
      this.to2 = this.getISODate(new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0))


      // this.from2 = firstDate.toISOString().slice(0, 10)
      // this.to2 = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0).toISOString().slice(0, 10);
      this.emitChanged(this.from2, this.to2)
    }

  },

  watch: {},

}
</script>

<style scoped>

</style>