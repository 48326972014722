<template>

  <v-select
      v-model="ticker"
      label="Ticker"
      :items="items"

      item-value="ticker"
      :item-text="item => item.ticker "
      return-object
      outlined
      clearable

      @change="change"
  ></v-select>

</template>

<script>

import gql from "graphql-tag";

export default {
  name: "CurrenciesCombobox",//change it

  data: function () {
    return {
      items: [],
      ticker: this.value,
    }
  },

  props: ['value'],

  apollo: {
    //$subscribe: {  where: {role: {_eq: "merchant"}}
    cryptocurrencies: {///1 change query name   cryptocurrencies and change body
      query: gql`
query {
  cryptocurrencies(where: {enabled: {_eq: true}}, order_by: {ticker: asc}) {
    ticker
  }
}
`,
      result({data}) {
        this.items = data.cryptocurrencies;///2 replace cryptocurrencies
      },
    },
    //  },
  },

  methods: {
    change(val) {
      this.$emit('input', val)//return   full user object, and then in filter   use field
    }
  },
}
</script>

