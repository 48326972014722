<template>
  <div>
    <v-select
        @change="change"

        v-model="value2"
        :label="selectLabel"
        :items="items"
        :item-text="item => _getItemText(item) "

        return-object
        outlined
        clearable
    ></v-select>
  </div>
</template>


<script>
export default {
  name: 'Select',

  data: function () {
    return {
      value2: {},
    }
  },

  props: [ 'val', 'selectLabel', 'getItemText', 'getItemData', 'items'],

  methods: {

    _getItemText(item) {
      return this.getItemText ? this.getItemText(item) : item
    },

    change(val) {
      if (this.getItemData)
        this.$emit('update:val', this.getItemData(val))
      else
        this.$emit('update:val', val)
    }

  },

  async mounted() {
    this.value2 = this.val
  },
}
</script>
