//import {apolloClient} from "./index";

import {apolloProvider} from './appoloProvider'


import gql from "graphql-tag";

const GET_USER = gql`query s($id: Int!) {
    users_by_pk(id: $id) {
        
        
        callback
        
        
        
        id
        
        
        
        
        role
        
        
        
        token
        
        username
        
        
    }
}`
// comment, rateFee, tg


export const user = {
    namespaced: true,

    state:
        {
            userDataLoaded: false,
            user: null,
            role: null,
            terminal: null,
            token: null,
            payoutToken: null,
            callback: null,
            banks: null,
            id: null,

            country:null,

            balance: 0,//subscrision
            payoutBalance: 0,//subscrision
        },

    actions: {

        //from balance subscrision
        setbalance(context, value) {
            context.commit('setbalance', value);
        },

        //from balance subscrision
        setpayoutBalance(context, value) {
            context.commit('setpayoutBalance', value);
        },

       onUserFetchRequest(context, uid) {
           return apolloProvider.defaultClient.query({query: GET_USER, variables: {id: uid}})
               .then(response => {
                let user2 = response.data.users_by_pk
                context.commit('setUser', user2);
            }).catch(
                (e)=>console.log('ERROR:onUserFetchRequest:'+e + 'permissions or role incorrect?')
               )
        },

        onUserClearRequest(context) {
            context.commit('clearUser');
        },

    },
    mutations: {

        setbalance(state, balance) {
            state.balance = balance
        },

        setpayoutBalance(state, payoutBalance) {
            state.payoutBalance = payoutBalance
        },

        setcallback(state, callback) {
            state.callback = callback
        },

        setbanks(state, banks) {
            state.banks = banks
        },

        clearUser(state) {
            state.userDataLoaded = false
            state.role = null
            state.terminal = null
            state.token = null
            state.payoutToken = null
            state.callback = null
            state.banks = null
            state.username = null
            state.id = null

        },

        setUser(state, user2) {
            state.role = user2.role
            state.terminal = user2.terminal
            state.token = user2.token
            state.payoutToken = user2.payoutToken
            state.callback = user2.callback
            state.banks = user2.banks
            state.username = user2.username
            state.id = user2.id
            state.country = user2.country
            state.userDataLoaded = true
        },

    }
}