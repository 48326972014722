var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      label: "Wallet",
      items: _vm.items,
      "item-value": "id",
      "item-text": (item) => item.network.name + " - " + item.wallet_address,
      "return-object": "",
      outlined: "",
      clearable: "",
    },
    on: { change: _vm.change },
    model: {
      value: _vm.id,
      callback: function ($$v) {
        _vm.id = $$v
      },
      expression: "id",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }