
// --- http connection and auth for apollo ----
import { concat, split} from "apollo-link";
import {createHttpLink} from "apollo-link-http";
import {WebSocketLink} from "apollo-link-ws";
import {getMainDefinition} from "apollo-utilities";
import {ApolloClient} from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import Vue from "vue";
import VueApollo from "vue-apollo";
import {getToken} from "./index";

import { setContext } from "apollo-link-context";

const authMiddleware = setContext( async () => {

    const token = await getToken();

    return {
        headers: {
            Authorization: token ? token : "",
        },
    };
})

const httpLink = new createHttpLink({
    // You should use an absolute URL here
    uri: `https://${process.env.VUE_APP_GQL}/v1/graphql`,
})
// --- ws connection and auth for apollo ----
const wsLink = new WebSocketLink({
    uri: `wss://${process.env.VUE_APP_GQL}/v1/graphql`, //gql/
    options: {
        reconnect: true,
        lazy: true,
        connectionParams: getConnectionParams
    },
})

async function  getConnectionParams() {
    let t = await getToken();
    return {
        headers: {Authorization: t}
    };
}

// сплит - проверяет запрос\подписка и раскидвает по разным коннектам
const link = split(
    // split based on operation type
    ({query}) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
    },
    wsLink,
    concat(authMiddleware, httpLink)
)
//// ---  connection and auth for apollo END ----


// Create the apollo client
export const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    connectToDevTools: true,
})

Vue.use(VueApollo)

export const apolloProvider = new VueApollo({
    defaultClient: apolloClient,

    errorHandler (error) {
        console.log('Global error handler')
        console.error(error)
    },
})
/*
export const config = {
    token: null
}

function getToken() {
    getHeaders().ge


    return config.token
}*/