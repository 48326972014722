var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "v-container",
        { staticClass: "d-flex flex-wrap pa-0 mt-2", attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "70", width: "300" },
            },
            [
              _c("CardsEditDialog", {
                attrs: { mode: "new", item: _vm.emptyItem },
                on: { ok: _vm.save },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              items: _vm.cards,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.network",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(item.network ? item.network.name : "-")
                    ),
                  ]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(item.status ? "Активна" : "Выключена") + " "
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "indigo",
                        attrs: { dense: "", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.setActive(item, !item.status)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.status ? "Откл" : "Вкл") + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c("CardsEditDialog", {
                      attrs: { caption: "правка", mode: "edit", item: item },
                      on: { ok: _vm.save },
                    }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "red",
                        attrs: { dense: "", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.del(item)
                          },
                        },
                      },
                      [_vm._v("Удалить")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Withdraws" }, [
      _c("h3", [_vm._v("Кошельки")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }