<template>


  <v-dialog
      v-model="dialog"
      width="700"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="indigo ma-3" text v-on="on"> Прямое пополнение</v-btn>
    </template>

    <v-card>
      <v-card-title primary-title>Прямое пополнение</v-card-title>
      <div>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="10">

                <v-select
                    v-model="user"
                    label="Выбор мерчанта"
                    :items="users"
                    :item-text="item => item.id + ' - ' + item.username "
                    outlined
                    clearable
                    return-object
                ></v-select>

                <v-select
                    v-model="card"
                    label="Выбор карты"
                    :items="cards"
                    :item-text="item => item.number + ' - ' + item.owner "
                    return-object
                    outlined
                ></v-select>

                <v-text-field
                    v-model="sum"
                    label="Сумма RUB"
                    outlined
                    clearable
                    type="number"
                ></v-text-field>

              </v-col>
            </v-row>
          </v-container>
        </v-form>

      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Отмена
        </v-btn>

        <v-btn
            color="primary"
            text
            @click="ok"
        >
          Ок
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>


//import gql from "graphql-tag";

export default {

  name: 'DirectRefillDialog',

  data: function () {
    return {
      dialog: false,
      sum: +0,

      card: null,
      user: null,

      cards: null,
      users: null
    }
  },
/*
  apollo: {



      users: {
        query: gql`

        query u {
  users(where: {role: {_eq: "merchant"}}, order_by: {id: asc}) {
    id
    role
    username
  }
}

`,
        result({data}) {
          this.users = data.users;
        },
      },

// !!! direct refill -  with  cards  that is turned off !!!
      cards: {
        query: gql`
        cards c {
  cards(where:  {deletedAt: {_is_null: true}}, order_by: {id: asc}) {
    id
    number
    owner
    status
    deletedAt
  }
}
`,
        result({data}) {
          this.cards = data.cards;
        },
      },
    },*/


  methods: {





    ok: function () {

      this.dialog = false;
      this.$emit('ok', this.user, this.card, this.sum)
    }
  },

  formatWallet(w) {
    return w.length < 10 ? w : `${w.slice(0, 4)} ... ${w.slice(-4)}`
  },

  computed: {
    balance() {
      return this.$store.state.user.balance
    },

  },


}

</script>
