var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-flex flex-wrap pa-0 ma-0", attrs: { fluid: "" } },
    [
      _c("span", { staticClass: "pa-2" }, [_vm._v("Балансы")]),
      _vm._l(_vm.balances, function (b) {
        return _c(
          "div",
          { key: b.ticker, staticClass: "pa-2" },
          [
            _vm._v(_vm._s(b.ticker) + " "),
            _c("FinanceCell", { attrs: { value: b.amount } }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }