var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { attrs: { dense: "" } },
    [
      _c(
        "v-list-item",
        { staticClass: "mb-10" },
        [
          _c(
            "v-list-item-action",
            [
              _c("v-img", {
                attrs: {
                  "max-height": "220",
                  "max-width": "132",
                  src: _vm.myImage,
                },
              }),
            ],
            1
          ),
          _c("v-list-item-content"),
        ],
        1
      ),
      _c(
        "v-list-item",
        [
          _c("v-list-item-action"),
          _c(
            "v-list-item-content",
            [_c("v-list-item-title", [_vm._v("Роль: " + _vm._s(_vm.role))])],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.sidePanel, function (item0) {
        return _c(
          "v-list-group",
          {
            key: item0.text,
            attrs: { "no-action": "", "prepend-icon": item0.icon },
            scopedSlots: _vm._u(
              [
                {
                  key: "activator",
                  fn: function () {
                    return [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            domProps: { textContent: _vm._s(item0.text) },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          _vm._l(item0.items, function (item, index) {
            return _c(
              "v-list-item",
              { key: index, attrs: { link: "", to: item.route } },
              [
                _c(
                  "v-list-item-action",
                  [
                    _c("v-icon", [_vm._v(_vm._s(item.icon))]),
                    item.badge
                      ? _c("v-badge", {
                          attrs: { color: "red", content: item.badge },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", {
                      domProps: { textContent: _vm._s(item.text) },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          1
        )
      }),
      _c(
        "v-list-item",
        { attrs: { link: "" }, on: { click: _vm.logout } },
        [
          _c("v-list-item-action", [_c("v-icon", [_vm._v("mdi-logout")])], 1),
          _c(
            "v-list-item-content",
            [_c("v-list-item-title", [_vm._v("Выйти")])],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }