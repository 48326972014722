var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-2" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1 mt-3",
            attrs: {
              dense: "",
              items: _vm.transactions,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.createdAt",
                fn: function ({ item }) {
                  return [
                    _c("DateTimeCell", { attrs: { value: item.createdAt } }),
                  ]
                },
              },
              {
                key: "item.fiat_amount",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(_vm.currency(item.fiat_amount)))]
                },
              },
              {
                key: "item.cryptocurrency_amount",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.currency(item.cryptocurrency_amount))
                    ),
                  ]
                },
              },
              {
                key: "item.currency_ticker",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.cryptocurrency_ticker))]
                },
              },
              {
                key: "item.fiat_ticker",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.fiat_ticker))]
                },
              },
              {
                key: "item.user_id",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(item.user_id + " : " + item.user?.username)
                    ),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    item.status !== "canceled"
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "green mr-1 mb-1",
                            on: {
                              click: function ($event) {
                                return _vm.action(item, "success")
                              },
                            },
                          },
                          [_vm._v("Одоб ")]
                        )
                      : _vm._e(),
                    item.status !== "canceled"
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "red mr-1 mb-1",
                            on: {
                              click: function ($event) {
                                return _vm.action(item, "canceled")
                              },
                            },
                          },
                          [_vm._v("Отмена ")]
                        )
                      : _vm._e(),
                    item.status !== "canceled"
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "red mr-1 mb-1",
                            on: {
                              click: function ($event) {
                                return _vm.action(item, "wrong_sum")
                              },
                            },
                          },
                          [_vm._v("Неверная сумма ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Transactions ma-2" }, [
      _c("h3", [_vm._v("Открытые транзакции(полный доступ)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }