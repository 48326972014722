<template>

  <div class="ma-3">
    <Snackbar ref="snackbar"></Snackbar>

    <div class="Transactions mt-10">
      <h3>Выплаты - Мерчант</h3>

    </div>

    <div>
      <PayoutEditDialog tag="-" mode="new" v-bind:item="emptyItem" v-on:ok="create"></PayoutEditDialog>

      <!--PayoutsRegisterUploadDialog caption="Загрузка реестра" tag="--" v-on:ok="upload"></PayoutsRegisterUploadDialog-->
      <span class="ma-2"></span>
      <NumberEditDialog caption="Пополнить баланс выплат" title="Пополнить баланс выплат" label="Сумма RUB" tag="--"
                        value="0" v-on:ok="balance"></NumberEditDialog>


    </div>

    <div>

      <DatePeriodPicker caption="Период выборки" :from="from" :to="to" v-on:changed="onPerodChanged"></DatePeriodPicker>

    </div>

    <div>
      <v-data-table
          dense
          :items="payouts"
          :headers="headers"
          class="elevation-1 mt-10"
          sort-by="id"
          sort-desc
          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.status="{ item }">
          <StatusCell :item="item"/>
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <DateTimeCell :value="item.updatedAt"></DateTimeCell>
        </template>
        <template v-slot:item.amount="{ item }">
          <FinanceCell :value="item.amount"/>
        </template>
        <template v-slot:item.hold="{ item }">
          <FinanceCell :value="item.hold"/>
        </template>

        <template v-slot:item.owner="{ item }"> {{ item.owner }}</template>

        <template v-slot:item.actions="{ item }">
          <v-btn class="red" v-if="item.status==='Pending' || item.status==='Hold' " dense text @click="del(item.id)">Отменить выплату
          </v-btn>

          <v-btn class="blue" v-if="item.status==='Success'  " dense text target="_blank"
                 :href="getDownloadLink(item.proof)">Скачать чек
          </v-btn>


          <span class="red--text" v-if="item.status==='Canceled'  ">{{ item.comment }}</span>
        </template>

      </v-data-table>
    </div>


  </div>

</template>

<script>


import gql from "graphql-tag";
import StatusCell from "./controls/StatusCell.vue";
import FinanceCell from "./controls/FinanceCell.vue";
import DateTimeCell from "./controls/DateTimeCell.vue";
import PayoutEditDialog from "./dialogs/PayoutEditDialog.vue";
//import PayoutsRegisterUploadDialog from "./dialogs/PayoutsRegisterUploadDialog.vue";
import DatePeriodPicker from "./controls/DatePeriodPicker.vue";
import NumberEditDialog from "./dialogs/NumberEditDialog.vue";
import Snackbar from "./controls/Snackbar.vue";
import {getHeaders} from "../store";
import {fetcher} from "../store";

const RestApiUrl = process.env.VUE_APP_REST_API_URL_MERCHANT  + "/payout"

export default {
  name: 'PayoutsAdmin',
  components: {
    DateTimeCell,
    FinanceCell,
    StatusCell,
    PayoutEditDialog,
    //PayoutsRegisterUploadDialog,
    DatePeriodPicker,
    NumberEditDialog,
    Snackbar
  },
  apollo: {
    $subscribe: {
      payouts: {
        query: gql`
subscription s($status: [String!],$from: timestamptz!, $to: timestamptz!) {
  payouts(

  where:
  {status: {_in: $status},
  _and:  {createdAt: {_gte: $from},
  _and:  {createdAt: {_lte: $to}
  }}
  }

  ) {
    id
    amount
    createdAt
    updatedAt

    status
    proof

    hold

    owner

    comment
  }
}
`,

        variables() {
          return {
            from: this.from,
            to: this.to,
            status: ["Pending", "Processing", "Success", "Canceled", "Hold"],

          }
        },

        result({data}) {
          this.payouts = data.payouts;
        },
      },
    },
  },

  data: () => ({

    emptyItem: {
      bankname: null,
      amount: null,
      number: null,
      owner: null
    },

    from: "2023-01-01",
    to: "2023-12-31" ,

    filterByDatePeriod: false,
    payouts: [],

    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Статус', value: 'status'},
      {text: 'Дата', value: 'updatedAt'},
      {text: 'Cумма', value: 'amount'},
      {text: 'Списано', value: 'hold'},
      {text: 'Получатель', value: 'owner'},
      {text: 'Действия', value: 'actions'},
    ],
  }),

  methods: {

    getDownloadLink(image) {
     // console.log(image);
      if (image === null)
        return;

      return `${process.env.VUE_APP_FILES_BASE }/proof/payout/${image.name}?type=${image.mimetype}`

    },

    onPerodChanged(val) {
      this.from = val.from
      this.to = val.to
    },


   async  balance(tag, item) {
      //console.log(item);

      /*
app.post("/api/merchant/convert
req.body.amount (num) сумма в rub
      */


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
            {
              amount: item,
            })
      };

      let url = `${process.env.VUE_APP_REST_API_URL_MERCHANT}/convert`

      fetcher(this.dosnackbar, url, requestOptions)


    },

    async create(item) {
     // console.log(item);

      /*
ручное создание одной выплаты
app.post("/api/merchant/payout",
req.body.amount,  (float)
req.body.bankname, (string)
req.body.number, (string)
req.body.owner, (string)
      */


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
            {
              amount: item.amount,
              bankname: item.bankname,
              number: item.number,
              owner: item.owner,
            })
      };

      let url = `${RestApiUrl}`

      fetcher(this.dosnackbar, url, requestOptions)

    },


    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    async accept(id) {

     // console.log(id);

      /*
        api админу
  изменить выплату
  app.patch("/api/admin/payout/:id");  (multipart/form-data)
  req.body.status (string)    ( db.WITHDRAWAL_STATUSES = ["Pending", "Processing", "Success", "Canceled"]; )
  req.files.doc (multipart/form-data)
       */

      const formData = new FormData();
      formData.append('status', 'Processing')

      let headers = await getHeaders()
      //headers['Content-Type'] = 'multipart/form-data'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formData
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    async del(id) {

      //console.log(id);

      /*
   отмена
app.delete("/api/merchant/payout/:id",
       */

      let headers = await getHeaders()
      //headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'DELETE',
        headers: headers,
        //body: JSON.stringify({status: 'Processing'})
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    async upload(id, file) {
      //console.log(id, file);

      /*
создание выплат CSV файлом (пока не готова логика, но точка есть)
app.post("/api/merchant/payout/list",
req.files.doc (multipart/form-data) (только csv)
       */

      const formData = new FormData();
      formData.append('doc', file)

      let headers = await getHeaders()

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formData
      };

      let url = `${RestApiUrl}/list`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },

    usdt(item) {
      //  console.log(item.offer)
      return (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0
    },
  },

}
</script>
