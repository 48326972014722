<template>
  <v-app>

    <v-app-bar
        v-if="isLoggedIn"
        app
        class="indigo"
        dark
        dense
    >

      <v-btn icon @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>


      <div v-if="isMerchant">
        <v-toolbar-title>
          {{ username }} - {{ terminal }} Terminal
        </v-toolbar-title>
      </div>
      <div v-else>
        <v-toolbar-title>
          {{ username }} - {{ country }} - административный модуль Recrypto.
        </v-toolbar-title>
      </div>

      <v-spacer></v-spacer>

      <!--v-btn
          class="indigo"
          href="https://t.me/noros_support" target="_blank">
        Поддержка 24/7
        <v-icon>mdi-send-circle</v-icon>
      </v-btn-->

      <v-btn icon @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>

    </v-app-bar>

    <v-navigation-drawer
        v-if="isLoggedIn"
        v-model="drawer"
        app
        dense
        clipped


        temporary
    >

      <AdminMenu v-if="isSuperAdmin"></AdminMenu>
      <SuperOperatorMenu v-if="isSuperOperator"></SuperOperatorMenu>
      <OperatorMenu v-if="isOperator"></OperatorMenu>
      <MerchantMenu v-if="isMerchant"></MerchantMenu>

      <ExchangeOperatorMenu v-if="isExchangeOperator"></ExchangeOperatorMenu>


    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>

  </v-app>
</template>

<script>

import router from './router'
import AdminMenu from "./components/menus/AdminMenu.vue";
import MerchantMenu from "./components/menus/MerchantMenu.vue";
import gql from "graphql-tag";
import OperatorMenu from "./components/menus/OperatorMenu.vue";
import SuperOperatorMenu from "./components/menus/SuperOperatorMenu.vue";
import ExchangeOperatorMenu from "./components/menus/ExchangeOperatorMenu.vue";

export default {
  name: 'App',
  components: {ExchangeOperatorMenu, AdminMenu, MerchantMenu, OperatorMenu, SuperOperatorMenu},

  data: () => ({
    users_by_pk: [],
    balance: 0,
    payoutBalance: 0,
    myImage: require('@/logo.svg'),
    drawer: true,
  }),

  created() {
    this.$route.meta.title = ` Вход в мерчант-аккаунт - Noros payments `
    document.title = this.$route.meta.title;
  },

  apollo: {
    $subscribe: {
      users_by_pk: {

        query: gql`subscription s($id: Int!)  {
              users_by_pk(id: $id) {
                  balance
                  payoutBalance
                }
              }`,

        variables() {
          return {
            id: this.id,
          }
        },

        // Disable the query


        result({data}) {
          this.balance = data.users_by_pk.balance;
          this.payoutBalance = data.users_by_pk.payoutBalance;
        },

        skip() {
          return true;//!this.isLoggedIn || !this.id || !this.isMerchant
        }
      },
    },
  },


  watch: {
    balance: function (val) {
      this.$store.dispatch('user/setbalance', val);
    },

    payoutBalance: function (val) {
      this.$store.dispatch('user/setpayoutBalance', val);
    },
  },

  computed: {

    bal() {
      return this.$store.state.user.balance
    },

    pbal() {
      return this.$store.state.user.payoutBalance
    },

    country() {
      return this.$store.state.user.country
    },

    id() {
      return this.$store.state.user.id
    },

    isLoggedIn() {
      return this.$store.state.user.userDataLoaded
    },

    username() {
      return this.$store.state.user.username
    },

    isSuperAdmin() {
      return this.$store.state.user.role === "superadmin" //ex admin
    },

    isMerchant() {
      return this.$store.state.user.role === "merchant"
    },

    isOperator() {
      return this.$store.state.user.role === "operator"
    },

    isExchangeOperator() {
      return this.$store.state.user.role === "exch_operator"
    },


    isSuperOperator() {
      return this.$store.state.user.role === "administrator" //ex super-operator
    },

    terminal() {
      return this.$store.state.user.terminal
    },

    isRubTerminal() {
      return this.$store.state.user.terminal === "RUB"
    },

  },

  methods: {

    toFixed2(num) {
      return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    },

    go(here) {
      router.push(here)
    },

    logout() {
      this.$store.dispatch('auth/onLogout');
      //this.$router.push({name:"Login"})
    }
  }
};
</script>
