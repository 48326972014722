<template>

  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>


    <div class="Transactions mt-10">
      <h3>ВЫПЛАТЫ - Админ</h3>
    </div>

    <div>
      <v-data-table
          dense
          :items="payouts"
          :headers="headers"
          class="elevation-1 mt-10"
          sort-by="id"
          sort-desc

          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.status="{ item }">   <StatusCell :item="item"/>  </template>
        <template v-slot:item.updatedAt="{ item }">  <DateTimeCell :value="item.updatedAt"></DateTimeCell></template>
        <template v-slot:item.amount="{ item }"> <FinanceCell :value="item.amount"/>  </template>

        <template v-slot:item.user="{ item }"> {{  item.user.username }}</template>

        <template v-slot:item.actions="{ item }">
          <v-btn class="blue" v-if="item.status==='Pending'  " dense text   @click="accept(item.id)">Взять в работу </v-btn>
          <ImagePickerDialog  v-if="item.status==='Processing'  "  caption="Выполнено" v-bind:tag="item.id"  v-on:ok="finish"  />
          <TextInputDialog v-if="item.status==='Processing'  " buttonclass="red" buttontext="Отклонить" caption="Причина" :tag="item.id" v-on:ok="del"></TextInputDialog>
        </template>

      </v-data-table>
    </div>
  </div>

</template>

<script>


import gql from "graphql-tag";
import StatusCell from "./controls/StatusCell.vue";
import FinanceCell from "./controls/FinanceCell.vue";
import DateTimeCell from "./controls/DateTimeCell.vue";
import TextInputDialog from "./dialogs/TextInputDialog.vue";


import ImagePickerDialog from "./dialogs/ImagePickerDialog.vue";
import {getHeaders} from "../store";

const RestApiUrl =  process.env.VUE_APP_REST_API_URL_ADMIN + "/payout"

import {fetcher} from "../store";
import Snackbar from "./controls/Snackbar.vue";




export default {
  name: 'PayoutsAdmin',
  components: {DateTimeCell, FinanceCell, StatusCell, ImagePickerDialog, TextInputDialog, Snackbar },
  apollo: {
    $subscribe: {
      payouts: {
        query: gql`
subscription s($status: [String!]) {
  payouts(where: {status: {_in: $status}}) {
    id
    amount
    updatedAt
    status

    number
    owner
    bankname

    user {
      username
    }

  }
}
`,

        variables() {
          return {
            status: ["Pending", "Processing"],
          }
        },

        result({data}) {
          this.payouts = data.payouts;
        },
      },
    },
  },

  data: () => ({

    from: "2023-01-01",
    to: "2023-01-01",
    filterByDatePeriod: false,
    payouts: [],
    test: "2021-01-01",

    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Статус', value: 'status'},

      {text: 'Время ', value: 'updatedAt'},
      {text: 'Cумма', value: 'amount'},

      {text: 'Карта', value: 'number'},
      {text: 'Банк', value: 'bankname'},

      {text: 'Мерч', value: 'user'},

      {text: 'Получатель', value: 'owner'},

      {text: 'Действия', value: 'actions'},
    ],
  }),


  methods: {





dosnackbar(text) {
  this.$refs.snackbar.showit(text)
},

    async accept(id){

      //console.log(id);

      /*
взять в работу выплату
PUT /api/admin/payout/:id
       */

      let headers = await getHeaders()

      const requestOptions = {
        method: 'PUT',
        headers: headers,
        //body: formData
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    async del(id, text){

     // console.log(id, text);

      /*
закрыть выплату
PATCH /api/admin/payout/:id    ( multipart/form-data )
req.body.status  ( может быть Success или Canceled )

если статус Success
то слать req.files.doc

если Canceled
то слать req.body.comment (string)
       */

      const formData  = new FormData();
      formData.append('status', 'Canceled')
      formData.append('comment', text )

      let headers = await getHeaders()
     // headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formData//JSON.stringify({status: 'Canceled', comment:text})
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    async finish(id, file){
     // console.log(id,file);

      /*

закрыть выплату
PATCH /api/admin/payout/:id    ( multipart/form-data )
req.body.status  ( может быть Success или Canceled )

если статус Success
то слать req.files.doc

если Canceled
то слать req.body.comment (string)

       */

      const formData  = new FormData();
      formData.append('status', 'Success')
      formData.append('doc', file )

      let headers = await getHeaders()
      //headers['Content-Type'] = 'multipart/form-data; boundary="----fucked boundary"'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formData
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },


    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },

    usdt(item) {
      //  console.log(item.offer)
      return (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0
    },
  },

}
</script>
