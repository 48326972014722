<template>
  <div class="d-flex justify-center ma-2">
    <span class="mr-1 mt-1"> Показывать на странице: </span>
    <v-select
        style="max-width: 80px"
        dense
        v-model="itemsPerPage"
        :items="Pages"
        return-object

        @change="change"
    ></v-select>
    <span v-if="totalRows" class="ml-1  mt-1"> строк из {{ totalRows }}</span>
  </div>
</template>

<script>
export default {
  name: "ItemsPerPage",

  data: function () {
    return {
      itemsPerPage: this.value,
      Pages: this.pages ? this.pages: [30, 50, 100, 1000, 10000],
    }
  },

  props: ['value','totalRows', 'pages'],

  methods: {

    change(val) {
        this.$emit('input', val )
    }

  },

}
</script>

<style scoped>

</style>