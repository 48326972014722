var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-center ma-2" },
    [
      _c("span", { staticClass: "mr-1 mt-1" }, [
        _vm._v(" Показывать на странице: "),
      ]),
      _c("v-select", {
        staticStyle: { "max-width": "80px" },
        attrs: { dense: "", items: _vm.Pages, "return-object": "" },
        on: { change: _vm.change },
        model: {
          value: _vm.itemsPerPage,
          callback: function ($$v) {
            _vm.itemsPerPage = $$v
          },
          expression: "itemsPerPage",
        },
      }),
      _vm.totalRows
        ? _c("span", { staticClass: "ml-1 mt-1" }, [
            _vm._v(" строк из " + _vm._s(_vm.totalRows)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }