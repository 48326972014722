<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Withdraws">
      <h3>Кошельки</h3>
    </div>


    <v-container fluid class="d-flex flex-wrap pa-0 mt-2 ">

      <v-card class="pa-2 mb-2 mr-2" height="70" width="300">
        <CardsEditDialog mode="new" v-bind:item="emptyItem" v-on:ok="save"></CardsEditDialog>
      </v-card>


    </v-container>


    <div>
      <v-data-table
          :items="cards"
          :headers="headers"
          class="elevation-1"
          sort-by="id"
          sort-desc

          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >


        <template v-slot:item.network="{ item }"> {{ item.network ? item.network.name : '-' }}</template>

        <template v-slot:item.status="{ item }">
          {{ item.status ? "Активна" : "Выключена" }}

          <v-btn dense class="indigo" text @click="setActive(item, !item.status)">{{
              item.status ? "Откл" : "Вкл"
            }}
          </v-btn>

        </template>

        <template v-slot:item.actions="{ item }">
          <CardsEditDialog caption="правка" mode="edit" v-bind:item="item" v-on:ok="save"></CardsEditDialog>

          <v-btn class="red" dense text @click="del(item)">Удалить</v-btn>


        </template>

      </v-data-table>
    </div>
  </div>

</template>

<script>

import CardsEditDialog from '../components/dialogs/WalletEditDialog2.vue'
import {getHeaders} from "../store";
import {fetcher} from "../store";
import Snackbar from "../components/controls/Snackbar.vue";
import gql from "graphql-tag";

const RestApiUrl = process.env.VUE_APP_REST_API_URL_ADMIN + "/card"

export default {
  name: 'Cards',

  components: {CardsEditDialog, Snackbar},

  apollo: {
    $subscribe: {
      wallets: {
        query: gql`
        subscription MyQuery {
  wallets {
    ticker
    state
    wallet_address
    wallet_balance
    wallet_comment
    min_payment_amount
    max_payment_amount
    id
    network_id
    network {
      name
    }
  }
}

        `,

        variables() {
        },

        result({data}) {
          this.cards = data.wallets;
        },
      },
    },
  },


  data: () => ({

    cards: [],
    country: "",
    chatId: 0,

    emptyItem: {
      ticker: "",
      state: "",
      wallet_address: "",
      wallet_balance: "",
      wallet_comment: "",
      min_payment_amount: "",
      max_payment_amount: "",
      network_id: 0,
    },

    headers: [
      {text: 'id', value: 'id'},
      {text: 'Тикер', value: 'ticker'},
      {text: 'state', value: 'state'},
      {text: 'wallet_address', value: 'wallet_address'},
      {text: 'wallet_balance', value: 'wallet_balance'},
      {text: 'wallet_comment', value: 'wallet_comment'},
      {text: 'min_payment_amount', value: 'min_payment_amount'},
      {text: 'max_payment_amount', value: 'max_payment_amount'},
      {text: 'network', value: 'network'},
      {text: 'Действия', value: 'actions'},
    ],

  }),

  methods: {

    currencychanged(value) {
      this.country = value === null ? "" : value.country

    },

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    setActive(item, active) {
      item.status = active
      this.save(item, "edit")
    },

    async save(item, mode) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: mode === "new" ? 'POST' : 'PATCH',
        headers: headers,
        body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${mode === "new" ? "" : item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async drop(item) {

      //PUT /api/admin/card/:id

      if (!window.confirm(`Сбросить счетчик баланса карты? \n ID: ${item.id};
        Номер: ${item.number};
        Владелец: ${item.owner};
        Банк: ${item.bank ? item.bank.name : '-'};


            `)) {

        return
      }


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PUT',
        headers: headers,
        // body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    async del(item) {


      if (!window.confirm(`УДАЛИТЬ карту !? \n ID: ${item.id};
        Номер: ${item.number};
        Владелец: ${item.owner};
        Банк: ${item.bank ? item.bank.name : '-'};


            `)) {

        return
      }


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'DELETE',
        headers: headers,
        // body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },
  },

}
</script>
