<template>

  <v-select
      v-model="id"
      label="Мерч"
      :items="users"

      item-value="id"
      :item-text="item => item.username "
      return-object
      outlined
      clearable

      @change="change"
  ></v-select>

</template>

<script>

import gql from "graphql-tag";

export default {
  name: "UserCombobox2",

  data: function () {
    return {
      users: [],
      id: this.value,
    }
  },

  props: ['value'],

  apollo: {
    //$subscribe: {  where: {role: {_eq: "merchant"}}
    users: {
      query: gql`
query {
  users(order_by: {username: desc}  ) {
    id
    username
  }
}
`,
      result({data}) {
        this.users = data.users;
      },
    },
    //  },
  },

  methods: {

    change(val) {
      this.$emit('input', val)//return   full user object, and then in filter   use field
    }

  },


}
</script>

